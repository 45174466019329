@import '../../common';

.home {
    opacity: 1;
    z-index: 1;
    width: 100%;
    height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    overflow: hidden;
}


/*------------------------------------------------------------------
    Banner
-------------------------------------------------------------------*/


.lbox-caption {
    display: table;
    height: 100%;
    width: 100%;
}

.lbox-caption {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
	z-index: 10;
}

.lbox-details {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
	position: relative;
}

.lbox-details::before{
	content: "";
	background: rgba(0,0,0,0.5);
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.lbox-details h1{
	font-size: 100px;
    font-family: 'Marck Script', cursive;
	color: #ffffff;
	position: relative;
	z-index: 3;
    margin: 0;
}

.lbox-details h2{
	font-size: 20px;
	color: #ffffff;
	position: relative;
	z-index: 3;
    font-weight: 400;
    margin: 0;
}
.lbox-details p, .lbox-details div{
	color: #ffffff;
	position: relative;
	z-index: 3;
    margin: 0;
    font-size: 20px;
}
.lbox-details p strong{
	color: #dd666c;
	font-size: 40px;
	font-family: "Sacramento", Arial, serif;
}
.lbox-details a.btn{
	background: #63c7bd;
	padding: 10px 20px;
	font-size: 20px;
	text-transform: uppercase;
	color: #ffffff;
	border-radius: 0px;
	position: relative;
	z-index: 3;
}
.lbox-details a.btn:hover{
	background: #dd666c;
}



/* mobile or only mobile */
@media (max-width: 767px) {
	.lbox-details h1{
		font-size: 30px;
	}
	.lbox-details h2{
		font-size: 16px;
	}
    .home {
        height: 500px;
    }
}



// COUNTDOWN CUSTOM
.simply-countdown {
    /* The countdown */
    margin-bottom: 2em;
}
.simply-countdown > .simply-section {
    /* coutndown blocks */
    display: inline-block;
    width: 100px;
    height: 100px;
    background: rgba($brand-primary,.8);
    margin: 0 4px;
    @include border-radius(50%);
    position: relative;

    animation: pulse 1s ease infinite, 
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.simply-countdown > .simply-section > div {
    /* countdown block inner div */
    display: table-cell;
    vertical-align: middle;
    height: 100px;
    width: 100px;
}

.simply-countdown > .simply-section .simply-amount,
.simply-countdown > .simply-section .simply-word {
	display: block;
	color: rgba($brand-white,1);
    /* amounts and words */
}

.simply-countdown > .simply-section .simply-amount {
	font-size: 30px;
    /* amounts */
}

.simply-countdown > .simply-section .simply-word {
	color: rgba($brand-white,.7);
	text-transform: uppercase;
	font-size: 12px;
    /* words */
}
