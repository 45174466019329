$brand-white: #fff;
$brand-primary: #dd666c !default;


$screen-sm:                  768px !default;
.box{
    scroll-margin: 30px;
	padding: 70px 0px;
}

.hide {
    display: none !important;
}

.visibility-hidden {
    visibility: hidden !important;
}

@media (max-width: 500px) {
    .hide-mobile {
        display: none !important;
    }
}

@media (min-width: 500px) {
    .hide-large-screen {
        display: none !important;
    }
}


@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
  }