@import '../../common';

.ceremonies {
    background-image: url('../../../assets/images/img_bg_3.jpg');
    background-size: cover;
    background-position: center;
     background-repeat: no-repeat;

    .overlay {
        background: rgba(0, 0, 0, 0.4);
    }
}

.event-wrap{
    text-align: center;
    border: 2px solid rgba($brand-white,.5);
    background:rgba($brand-white,.1);
    padding: 30px;
    width: 100%;
    font-family: 'Barlow', sans-serif;
    @include border-radius(4px);
    @media screen and (max-width: $screen-sm) {
        margin-bottom: 10px;
    }
    h3{
        font-size: 20px;
        color: $brand-white;
        border-bottom: 1px solid rgba($brand-white,.5);
        display: block;
        padding-bottom: 20px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 400;
        margin: 0 0 20px 0;
    }
    div,span{
        display: block;
        color: rgba($brand-white,.8);
    }

    .event-col-container{
        display: flex;
    }

    .i{
        color: rgba($brand-white,1);
        font-size: 20px;
    }
    .event-col{
        width: 50%;
        float: left;
        margin-bottom: 30px;
    }

    .description {
        display: inline-block;
        margin-top: 3rem;
    }

    .note {
        display: inline-block;
        margin-top: 3rem;
        font-weight: bolder;
        font-style: italic;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 5rem;

        .button {
              margin-left: 1rem;
              background-color: transparent;
              border: 2px outset #ffffff
        }
    }
}