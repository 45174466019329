@import '../../../common';

.public-app-main {
    header {
            position: fixed;
            width: 100%;
            box-shadow: 0 1px 5px #A9A9A9;
            background-color: #FFFFFF;
            height: 100px;
            padding: 10px 0;
            border-bottom: 2px double #A9A9A9;
            font-size: 1.6rem;
            top: 0;
            z-index: 2;

         nav {     
            @media (min-width: 500px) {
            padding: 10px 0;
            }
            .navbar-brand {
                text-transform: capitalize;
                font-size: 2.5rem;
                color: #dd666c;
                font-family: "Sacramento", Arial, serif;
                margin: auto;
            }

             .menu-options {
                display: flex;    
                justify-content: flex-end;
                flex-basis: auto;
                flex-grow: 1;
                align-items: center;
             }
        }
    }


    .app-content {
        margin-top: 100px;

        article {
            flex: 2;

            .content {
                min-height: 70vh;
                padding-bottom: 2rem;
            }
        }
        
        footer {
            display: flex;
            background-color: #FFFFFF;
            border-top: 1px solid #A9A9A9;
            align-items: center;
            flex-direction: column;
            clear: both;
            bottom: 0;
            width: 100%;

            div {
                margin-bottom: 0.5rem;
                img {
                    height: 3rem;
                }
            }
        }
    }
}


  .navbar-nav {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li button {
        padding: 19px 15px;
        text-transform: uppercase;
        font-size: 18px;

        &.active {
            background: #dd666c;
            color: #ffffff;
        }

        &:hover {
            background: #63c7bd;
            color: #ffffff;
        }
    }
    
}

.flex-column {
    flex-direction: column;

    li button {
        padding: 5px 15px;
        display: block;
        width: 100%;
    }
}
