/*------------------------------------------------------------------
    IMPORT FONTS
-------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Barlow:100,200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Marck+Script');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,300,600,400italic,700');
@import url('https://fonts.googleapis.com/css?family=Sacramento');

/*------------------------------------------------------------------
    IMPORT FILES
-------------------------------------------------------------------*/

@import './common';


/*------------------------------------------------------------------
    SKELETON
-------------------------------------------------------------------*/

* {
  box-sizing: border-box;
}

html {
	font-size: 10px;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	font-family: sans-serif; // 1
	-ms-text-size-adjust: 100%; // 2
	-webkit-text-size-adjust: 100%; // 2
}

body {
	margin: 0;
	font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.7;
    color: #828282;
    background: #fff;
}


a, button {
  color: #1f1f1f;
  text-decoration: none;
  outline: none;
  background: none;
  border: none;
}

a, button {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

ul, li, ol {
  list-style: none;
  margin: 0px;
  padding: 0px;
}


::-webkit-selection {
  color: $brand-white;
  background: $brand-primary;
}

::-moz-selection {
  color: $brand-white;
  background: $brand-primary;
}
::selection {
  color: $brand-white;
  background: $brand-primary;
}