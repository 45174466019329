
.title-box{
	text-align: center;
	margin-bottom: 30px;
 h2{
	font-size: 60px;
	font-family: 'Marck Script', cursive;
	color: #222222;
    margin: 0;
 }

 h3{
    font-family: "Work Sans", Arial, sans-serif;
	font-size: 24px;
	color: #222222;
    margin: 0;
    font-weight: 400;
 }

 p {
    white-space: pre-line;
    margin-bottom: 1rem;
 }

.circle-image {
    width: 200px;
 height: 200px;
  -webkit-border-radius: 50%;
 -moz-border-radius: 50%;
  -ms-border-radius: 50%;
   border-radius: 50%;
   background-size: auto;
  background-position: center;
   background-repeat: no-repeat;
   object-fit: cover;
 }
}

/* mobile or only mobile */
@media (max-width: 767px) {
	.title-box {
        h2{
		font-size: 28px;
	}
}
}